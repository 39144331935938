import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AddCustomAttributeComponent } from './add-custom-attribute/add-custom-attribute.component';
import { CustomAttributesListComponent } from './custom-attributes-list/custom-attributes-list.component';
import { DynamicInputFieldDirective } from './dynamic-input-field.directive';
import { MarginDirective } from './margin.directive';

//partials
import { PartialsModule } from '../../partials/partials.module';
//tabs
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { ImageViewerModule } from '@hallysonh/ngx-imageviewer';
import { GalleryModule } from 'ng-gallery';
import { LightboxModule } from 'ng-gallery/lightbox';

//material
import { AgmCoreModule } from '@agm/core';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { OverlayModule } from '@angular/cdk/overlay';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTreeModule } from '@angular/material/tree';
import { RxReactiveFormsModule } from '@rxweb/reactive-form-validators';
import { AgGridModule } from 'ag-grid-angular';
import { InlineSVGModule } from 'ng-inline-svg';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { NgxSpinnerModule } from "ngx-spinner";
import { DeleteRelationDialogComponent } from './Relationship/_subs/delete-relation-dialog/delete-relation-dialog.component';
import { EditRelationDialogComponent } from './Relationship/_subs/edit-relation-dialog/edit-relation-dialog.component';
import { RelationshipDialogComponent } from './Relationship/relationship-dialog/relationship-dialog.component';
import { AddActivityComponent } from './add-activity/add-activity.component';
import { AddFieldComponent } from './add-field/add-field.component';
import { AdvancedSearchComponent } from './advanced-search/advanced-search.component';
import { AlertDialogComponent } from './alert-dialog/alert-dialog.component';
import { AttachmentRenderrerComponent } from './attachment-renderrer/attachment-renderrer.component';
import { AttachmentViewComponent } from './attachment-view/attachment-view.component';
import { AutoTrimSpacesDirective } from './auto-trim-spaces.directive';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { AddNewCompanyComponent } from './custom-add/add-new-company/add-new-company.component';
import { ActivityFilterComponent } from './custom-edit/activity-filter/activity-filter.component';
import { EditActivityReportComponent } from './custom-edit/edit-activity-report/edit-activity-report.component';
import { EditCellRendererCompanyComponent } from './custom-edit/edit-cell-renderer-company/edit-cell-renderer-company.component';
import { EditCompaniesDialogComponent } from './custom-edit/edit-companies-dialog/edit-companies-dialog.component';
import { EditApprovalComponent } from './edit-approval/edit-approval.component';
import { EditCellRendererComponent } from './edit-cell-renderer/edit-cell-renderer.component';
import { EditCustomAttributeComponent } from './edit-custom-attribute/edit-custom-attribute.component';
import { EditFieldComponent } from './edit-field/edit-field.component';
import { FieldVisibilitySettingComponent } from './field-visibility/field-visibility-setting/field-visibility-setting.component';
import { FieldsListComponent } from './fields-list/fields-list.component';
import { ImageViewerComponent } from './image-viewer/image-viewer.component';
import { LocationDialogComponent } from './location-dialog/location-dialog.component';
import { SearchPipe } from './pipes/search.pipe';
import { PreviewDisplayOrderComponent } from './preview-display-order/preview-display-order.component';
import { EventEmitterService } from './services/event-emitter.service';
import { StrictNumberOnlyDirectiveDirective } from './strict-number-only-directive.directive';
import { SwitchComponent } from './switch-toggle/switch.component';
import { TwoDigitDecimaNumberDirectiveDirective } from './two-digit-decima-number-directive.directive';
import { AdvanceFilterComponent } from './advance-filter/advance-filter.component';

@NgModule({
  	declarations: [
	 	  MarginDirective,
			DynamicInputFieldDirective,
			CustomAttributesListComponent,
			AddCustomAttributeComponent,
			StrictNumberOnlyDirectiveDirective,
			EditCustomAttributeComponent,
			SearchPipe,
			FieldsListComponent,
			EditCellRendererComponent,
			AddFieldComponent,
			EditFieldComponent,
			RelationshipDialogComponent,
			PreviewDisplayOrderComponent,
			EditRelationDialogComponent,
			DeleteRelationDialogComponent,
			AlertDialogComponent,
			AddNewCompanyComponent,
			ConfirmDialogComponent,
			EditCellRendererCompanyComponent,
			EditCompaniesDialogComponent,
			AddActivityComponent,
			TwoDigitDecimaNumberDirectiveDirective,
			AutoTrimSpacesDirective,
			LocationDialogComponent,
			EditActivityReportComponent,
			ActivityFilterComponent,
			SwitchComponent,
			AttachmentRenderrerComponent,
			AttachmentViewComponent,
			ImageViewerComponent,
			EditApprovalComponent,
			AdvancedSearchComponent,
			FieldVisibilitySettingComponent,
			AdvanceFilterComponent
	],
	imports: [
		CommonModule,
		InlineSVGModule,
		MatButtonModule,
		MatMenuModule,
		MatSelectModule,
		MatInputModule,
		MatTableModule,
		MatAutocompleteModule,
		MatRadioModule,
		MatIconModule,
		MatNativeDateModule,
		MatProgressBarModule,
		MatDatepickerModule,
		MatCardModule,
		MatPaginatorModule,
		MatSortModule,
		MatCheckboxModule,
		MatProgressSpinnerModule,
		MatSnackBarModule,
		MatExpansionModule,
		MatTabsModule,
		MatTooltipModule,
		MatDialogModule,
		FormsModule,
		ReactiveFormsModule,
		PartialsModule,
		NgbModule,
		NgxMatSelectSearchModule,
		MatChipsModule,
		MatTreeModule,
		MatListModule,
		OverlayModule,
		AgGridModule.withComponents([EditCellRendererComponent, EditCellRendererCompanyComponent, AttachmentRenderrerComponent]),
		NgxSpinnerModule,
		AgmCoreModule.forRoot({
			apiKey: 'AIzaSyBWlY9IfA0AyRIz4DKL9W2wFFkxtXlKanw'
		}),
		DragDropModule,
		GalleryModule,
    	LightboxModule,
		ImageViewerModule,
		RxReactiveFormsModule
	],
	exports: [
		MarginDirective,
		DynamicInputFieldDirective,
		AddCustomAttributeComponent,
		CustomAttributesListComponent,
		StrictNumberOnlyDirectiveDirective,
		InlineSVGModule,
		MatButtonModule,
		MatMenuModule,
		MatSelectModule,
		MatInputModule,
		MatTableModule,
		MatAutocompleteModule,
		MatRadioModule,
		MatIconModule,
		MatNativeDateModule,
		MatProgressBarModule,
		MatDatepickerModule,
		MatCardModule,
		MatPaginatorModule,
		MatSortModule,
		MatCheckboxModule,
		MatProgressSpinnerModule,
		MatSnackBarModule,
		MatExpansionModule,
		MatTabsModule,
		MatTooltipModule,
		MatDialogModule,
		FormsModule,
		ReactiveFormsModule,
		PartialsModule,
		NgbModule,
		MatChipsModule,
		NgxMatSelectSearchModule,
		SearchPipe,
		MatTreeModule,
		AgGridModule,
		MatListModule,
		FieldsListComponent,
		AddFieldComponent,
		EditFieldComponent,
		RelationshipDialogComponent,
		PreviewDisplayOrderComponent,
		AddNewCompanyComponent,
		EditCellRendererCompanyComponent,
		EditCompaniesDialogComponent,
		NgxSpinnerModule,
		AddActivityComponent,
		AutoTrimSpacesDirective,
		LocationDialogComponent,
		SwitchComponent,
	],
	providers: [
		EventEmitterService
	],
	entryComponents:[
		 AddCustomAttributeComponent,
		 EditCustomAttributeComponent,
		 AddFieldComponent,
		 EditFieldComponent,
		 RelationshipDialogComponent,
		 PreviewDisplayOrderComponent,
		 EditRelationDialogComponent,
		 DeleteRelationDialogComponent,
		 AddNewCompanyComponent,
		 AlertDialogComponent,
		 ConfirmDialogComponent,
		 EditCompaniesDialogComponent,
		 AddActivityComponent,
		 LocationDialogComponent,
		 EditActivityReportComponent,
		 AttachmentViewComponent,
		 ActivityFilterComponent,
		 ImageViewerComponent,
		 EditApprovalComponent,
		 AdvancedSearchComponent,
		 FieldVisibilitySettingComponent
	]

})
export class SharedModule { }
