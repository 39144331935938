export enum CustomAttributeTypeEnum{

    SINGLE_SELECT_DROPDOWN=1,
    MULTI_SELECT_DROPDOWN=2,
    DATE=3,
    IMAGE=4,
    RADIO=5,
    TEXT_SINGLE_LINE=6,
    NUMERIC=7,
    TEXT_MULTI_LINE=8,
    EMAIL=9,
    HEADER_TEXT=10,
    NORMAL_TEXT=11,
    PAGE_SEPARATOR=12,
    LINK_FROM_OTHER_ENTITIES=13,
    CALCULATED_FIELD=14,
    DOCUMENT_UPLOAD=15,
    AUDIO_VIDEO=16,
    REFERENCE_DOCUMENTS=17,
    DATA_LIST=18,
    OTP_VALIDATION=19,
    QR_CODE_SCANNER=20,
    DATE_TIME=21,
    TIME_DURATION=22,

    //Static Attribute Type, It'll be used in Advance Filter Screen
    CREATED_UPDATED_DATE = -1

}